import { Link } from "gatsby";
import React from "react";

import { rhythm, scale } from "../../utils/typography";
import { serifFam } from "../../utils/fonts";

function PostSetItem({ post, currentPost }) {
  const item = post.frontmatter.set_title || post.frontmatter.title;
  const isCurrentItem = post.frontmatter.slug === currentPost.frontmatter.slug;
  return (
    <Link
      css={{
        color: `white`,
        fontWeight: isCurrentItem ? `bold` : `normal`,
        marginTop: `5rem`,
      }}
      to={post.fields.path}
    >
      {item}
    </Link>
  );
}

const PostSetList = ({ posts, currentPost, css, ...props }) => {
  return (
    <div
      css={{
        display: `grid`,
        gridGap: rhythm(1),
        gridTemplateColumns: `auto 1fr`,
        color: `white`,
        ...css,
      }}
      {...props}
    >
      <div
        css={{
          ...scale(2.2),
          lineHeight: `4rem`,
          fontFamily: serifFam,
        }}
      >
        {currentPost.frontmatter.set_order}/{posts.length}
      </div>
      <ol css={{ margin: 0, paddingLeft: rhythm(1) }}>
        {posts.map((post, index) => (
          <li css={{ marginLeft: 0, marginRight: 0 }} key={index}>
            <PostSetItem post={post} currentPost={currentPost} />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default PostSetList;
