import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

import BaseLayout from "../components/base-layout";
import Container from "../components/container";
import Content from "../components/content";
import ContentAuthor from "../components/content-author";
import ContentBox from "../components/content-box";
import ContentCTA from "../components/content-cta";
import InvertedContentBox from "../components/inverted-content-box";
import PostHeader from "../components/post-header";
import PostSetList from "../components/post-set-list";
import Spacer from "../components/spacer";
import presets from "../utils/presets";
import { rhythm, scale } from "../utils/typography";
import { getBodyStyleByHash } from "../utils/styles";
import { partition } from "../utils/string";

function generateDripTagsForPost(post) {
  const dripTags = post.frontmatter.topics.map(
    topic => `submitted:category:${topic}`,
  );
  dripTags.push(`submitted:post-slug:${post.frontmatter.slug}`);
  return dripTags;
}

class PostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const bioPlacement = post.frontmatter.bioPlacement || 2;
    const [postIntro, postMain] = partition(post.html, "</p>", bioPlacement);
    const postSegments = postMain.split(`[cta]`);
    const setPosts = post.frontmatter.set
      ? this.props.data.allMarkdownRemark.edges.map(item => item.node)
      : null;
    const disciplines = this.props.data.allDisciplinesJson.edges.map(
      item => item.node,
    );
    const dripTags = generateDripTagsForPost(post);
    return (
      <BaseLayout>
        <div className="post">
          <Helmet title={post.frontmatter.title}>
            <body
              css={{
                ...getBodyStyleByHash(post.frontmatter.slug),
              }}
            />
          </Helmet>
          <Container>
            <PostHeader
              title={post.frontmatter.title}
              subtitle={post.frontmatter.subtitle}
              rhythmWidth={20}
            />
            {post.frontmatter.set && [
              <Spacer size={2} />,
              <PostSetList posts={setPosts} currentPost={post} />,
            ]}
          </Container>
          <Spacer size={2} />
          <Container
            css={{
              [presets.Desktop]: {
                gridTemplateColumns: `36rem 1fr`,
                gridTemplateRows: `auto`,
                gridGap: rhythm(0.8),
                [presets.Desktop]: {
                  gridGap: rhythm(1),
                },
                [presets.DesktopLg]: {
                  gridGap: rhythm(1.5),
                },
                [presets.Wide]: {
                  gridGap: rhythm(2),
                },
              },
            }}
            className="post-grid"
          >
            <main>
              {postSegments.map((postSegment, index) => [
                <ContentBox className="content" key={index}>
                  <Spacer size={2} />
                  {index === 0 && [
                    <Content
                      html={postIntro}
                      css={{ "& > p:first-of-type": { ...scale(0.4) } }}
                      key={"content"}
                    />,
                    <ContentAuthor
                      key={"contentauthor"}
                      authorBio={
                        post.frontmatter.authorBio ||
                        post.frontmatter.author.bio
                      }
                      avatarResolutions={
                        post.frontmatter.author.avatar.children[0].fixed
                      }
                    />,
                  ]}
                  <Content html={postSegment} />
                  <Spacer size={2} />
                </ContentBox>,
                <Spacer size={2} />,
                <InvertedContentBox className="cta">
                  <Spacer size={1} />
                  <ContentCTA
                    dripFormId={post.frontmatter.dripFormId}
                    ctaHeadline={post.frontmatter.ctaHeadline}
                    ctaBody={post.frontmatter.ctaBody}
                    ctaButton={post.frontmatter.ctaButton}
                    disciplines={disciplines}
                    dripTags={dripTags}
                  />
                  <Spacer size={1} />
                </InvertedContentBox>,
                <Spacer size={2} />,
              ])}
            </main>
            <aside
              css={{
                display: `none`,
                [presets.DesktopLg]: {
                  display: `block`,
                  ...scale(-0.2),
                },
                [presets.Wide]: {
                  ...scale(0),
                },
              }}
            >
              <ContentCTA
                dripFormId={post.frontmatter.dripFormId}
                ctaHeadline={post.frontmatter.ctaHeadline}
                ctaBody={post.frontmatter.ctaBody}
                ctaButton={post.frontmatter.ctaButton}
                disciplines={disciplines}
                dripTags={dripTags}
              />
            </aside>
          </Container>
        </div>
      </BaseLayout>
    );
  }
}

export default PostTemplate;

export const pageQuery = graphql`
  query PostBySlug($slug: String!, $set: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      timeToRead
      fields {
        path
      }
      frontmatter {
        author {
          first_name
          full_name
          bio
          avatar {
            children {
              ... on ImageSharp {
                fixed(width: 100, height: 100, quality: 75) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
        }
        authorBio
        bioPlacement
        title
        set
        set_title
        set_order
        slug
        subtitle
        topics
        date(formatString: "MMMM DD, YYYY")
        dripFormId
        ctaHeadline
        ctaBody
        ctaButton
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { set: { eq: $set } }
        fields: { sourceInstanceName: { eq: "posts" } }
      }
      sort: { fields: [frontmatter___set_order], order: ASC }
    ) {
      edges {
        node {
          fields {
            path
          }
          frontmatter {
            title
            set
            set_title
            set_order
            slug
          }
        }
      }
    }
    allDisciplinesJson {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`;
